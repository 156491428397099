body {
    margin: 0;
    font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Rubik', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button.FilterButton.Mui-selected,
button.FilterButton.Mui-active {
    background-color: #59358C !important;
}

button.CinnamonButton:hover,
a.CinnamonButton:hover {
    background-color: #59358C;
    color: white;
}

.inetf-cookie.banner {
    position: sticky;
    bottom: 0px;
    width: 100%;
    background-color: #2a1305;
    color: white;
    padding: 5px;
    font-family: Rubik, sans-serif;
    font-size: smaller;
}

.inetf-cookie.accept {
    margin: 5px;
    padding: 5px;
    background-color: #6d3cff;
    color: white;
    border: none;
}

.inetf-cookie.accept:hover {
    margin-left: 6px;
    margin-right: 4px;
    cursor: pointer;
}

.inetf-cookie.decline {
    margin: 5px;
    padding: 5px;
    background-color: #ff5056;
    color: white;
    border: none;
}

.inetf-cookie.decline:hover {
    margin-left: 6px;
    margin-right: 4px;
    cursor: pointer;
}

.privacyLink {
    color: white;
}